import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Select from 'react-select'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import JobGridsFourComp from '../../components/job-grids-four-comp'
import Feature from '../../components/Feature'
import { useDispatch, useSelector } from 'react-redux'
import CustomLoader from '../../components/CustomLoader'
import CountriesComponent from '../../components/CountriesComponent';
import { getJobs } from '../../redux/features/generalSlice'
import ExploreJob from '../../components/Explore-job'

const optionsTwo = [
    { value: 'full_time', label: 'Full Time' },
    { value: 'part_time', label: 'Part Time' },
    { value: 'freelance', label: 'Freelance' },
    { value: 'temporary', label: 'Temporary' },
    { value: 'internship', label: 'Internship' },
    { value: 'apprenticeship', label: 'Apprenticeship' },
    { value: 'contractor', label: 'Contractor' },
    { value: 'consultancy', label: 'Consultancy' },
]

export default function JobGridsFour() {

    // const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const jobsPagination = useSelector(state => state.generalReducer.jobsPagination)
    const jobs = useSelector(state => state.generalReducer.jobs)
    const [allJobs, setAllJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const allParams = {};
    queryParams.forEach((value, key) => {
        allParams[key] = value;
    });
    useEffect(() => {
        if (jobs && jobs?.length) return
        setIsLoading(true)
        dispatch(getJobs({}))
    }, [])

    useEffect(() => {
        if (!jobs?.length) return
        const tmpJobs = JSON.parse(JSON.stringify(allJobs));
        setAllJobs(tmpJobs.concat(jobs));
    }, [jobs])

    useEffect(() => {
        if (!allJobs.length) return
        if (jobsPagination?.count != allJobs.length && jobsPagination?.next)
            dispatch(getJobs({ paginationUrl: jobsPagination.next }))

        // if (jobsPagination?.count === allJobs.length) {
        setFilteredJobs(allJobs)

        const inputField = document.getElementById('keywordSearch');
        inputField.addEventListener('keydown', function (event) {
            if (event.key === 'Enter') searchJob()
        });

        if (allParams['keyWord'] && allParams['keyWord'] !== '' && allParams['keyWord'] !== 'null')
            document.getElementById('keywordSearch').value = allParams['keyWord']
        if (allParams['country'] && allParams['country'] !== '' && allParams['country'] !== 'null')
            document.getElementById('countrySearch').value = allParams['country']
        if (allParams['jobType'] && allParams['jobType'] !== '' && allParams['jobType'] !== 'null')
            document.getElementById('jobTypeSearch').value = allParams['jobType']

        searchJob()
        setIsLoading(false)
        // }
    }, [allJobs])

    const searchJob = async () => {
        const keyWord = document.getElementById('keywordSearch').value != '' ? document.getElementById('keywordSearch').value.toLowerCase() : null;
        const country = document.getElementById('countrySearch').value != 0 ? document.getElementById('countrySearch').value.toLowerCase() : null;
        const jobType = document.getElementById('jobTypeSearch').value != 0 ? document.getElementById('jobTypeSearch').value.toLowerCase() : null;
        if (!keyWord && !country && !jobType) {
            setFilteredJobs(allJobs)
            const currentUrl = window.location.href;
            const updatedUrl = currentUrl.split('?')[0];
            window.history.replaceState({}, document.title, updatedUrl);
        }
        if (keyWord && country && jobType) {
            setFilteredJobs(allJobs.filter(j => (
                j.position_name.toLowerCase().includes(keyWord) &&
                j.address.toLowerCase().includes(country) &&
                j.contract_details.toLowerCase().includes(jobType)
            )))
        } else if (keyWord && country) {
            setFilteredJobs(allJobs.filter(j => (
                j.position_name.toLowerCase().includes(keyWord) &&
                j.address.toLowerCase().includes(country)
            )))
        } else if (keyWord && jobType) {
            setFilteredJobs(allJobs.filter(j => (
                j.position_name.toLowerCase().includes(keyWord) &&
                j.contract_details.toLowerCase().includes(jobType)
            )))
        }
        else if (country && jobType) {
            setFilteredJobs(allJobs.filter(j => (
                j.address.toLowerCase().includes(country) &&
                j.contract_details.toLowerCase().includes(jobType)
            )))
        }
        else if (keyWord) {
            setFilteredJobs(allJobs.filter(j => (
                j.position_name.toLowerCase().includes(keyWord)
            )))
        } else if (country) {
            setFilteredJobs(allJobs.filter(j => (
                j.address.toLowerCase().includes(country)
            )))
        } else if (jobType) {
            setFilteredJobs(allJobs.filter(j => (
                j.contract_details.toLowerCase().includes(jobType)
            )))
        }
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />

            {
                isLoading &&
                <CustomLoader tipText="Loading Job Details.." />
            }

            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Job Vacancies</h3>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative -mt-[42px] md:pb-24 pb-16">
                <div className="container z-1">
                    <div className="d-flex" id="reserve-form">
                        <div className="md:w-5/6 mx-auto">
                            <div className="lg:col-span-10">
                                <div className="bg-white dark:bg-slate-900 border-0 shadow rounded-md p-3">
                                    <form action="#">
                                        <div className="registration-form text-dark text-start">
                                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                                <div className="filter-search-form relative filter-border">
                                                    <i className="uil uil-briefcase-alt icons"></i>
                                                    <input name="name" id="keywordSearch" type="text" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" placeholder="Search your Keywords" />
                                                </div>

                                                <div className="filter-search-form relative filter-border">
                                                    <i className="uil uil-map-marker icons"></i>

                                                    <select id="countrySearch" className='form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0'>
                                                        <option selected value="0">All</option>
                                                        <CountriesComponent></CountriesComponent>
                                                    </select>

                                                </div>

                                                <div style={{ display: 'none' }} className="filter-search-form relative filter-border">
                                                    <i className="uil uil-briefcase-alt icons"></i>
                                                    <select id="jobTypeSearch" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0">
                                                        <option selected value="0">All</option>
                                                        <option value="full_time">Full Time</option>
                                                        <option value="part_time">Part Time</option>
                                                        <option value="freelance">Freelance</option>
                                                        <option value="temporary">Temporary</option>
                                                        <option value="internship">Internship</option>
                                                        <option value="apprenticeship">Apprenticeship</option>
                                                        <option value="contractor">Contractor</option>
                                                        <option value="consultancy">Consultancy</option>
                                                    </select>

                                                </div>

                                                <input onClick={searchJob} id="search" name="search" style={{ height: '60px;', cursor: 'pointer' }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100" value="Search" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    filteredJobs !== null && filteredJobs?.length && <JobGridsFourComp jobs={filteredJobs} />
                }

                {
                    filteredJobs !== null && !filteredJobs?.length && <h5 style={{ textAlign: 'center' }}>No jobs found</h5>
                }
                {/* <Feature /> */}

                <ExploreJob />

            </section>
            <Footer />
        </>
    )
}





