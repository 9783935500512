import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';

import Navbar from '../../components/Navbar'
import EmployerList from '../../components/employer-list'
import ab01 from '../../assets/images/about/ab01.jpg';
import ab02 from '../../assets/images/about/ab02.jpg';
import Footer from '../../components/Footer';
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { useDispatch, useSelector } from 'react-redux'
import { getClients } from '../../redux/features/generalSlice';
import CustomLoader from '../../components/CustomLoader';
import ExploreJob from '../../components/Explore-job';

export default function Employerlist() {
    const [isOpen, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    }

    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const clients = useSelector(state => state.generalReducer.clients)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClients({}))
    }, [])

    return (
        <>
            <Navbar navClass='justify-end nav-light' />

            {
                isApiLoading &&
                <CustomLoader tipText="Loading Schools.." />
            }

            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Our Schools</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Our Schools</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                {
                    clients && clients.length && <EmployerList clients={clients.filter(c => c.is_visible || c.is_public)} />
                }

                {
                    clients && !clients.length && <h5 style={{ textAlign: 'center' }}>No schools found</h5>
                }
                <ExploreJob />
            </section>
            <Footer />

        </>
    )
}
