import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import bg5 from '../../assets/images/hero/bg5.jpg';
import image from '../../assets/images/team/01.jpg';
import shree_logo from '../../assets/images/company/shree-logo.png';
import circle_logo from '../../assets/images/company/circle-logo.png';
import Navbar from '../../components/Navbar'
import CandidateDetailComp from '../../components/candidate-detail-comp';
import Footer from '../../components/Footer';
import { FileText, Gift, Globe, Home, Mail, MapPin, Phone, Server } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../redux/features/generalSlice';
import CustomLoader from '../../components/CustomLoader';
import moment from "moment";
import ExploreJob from '../../components/Explore-job';

export default function CandidateDetail() {

    const location = useLocation();
    const dispatch = useDispatch();
    const [jobId, setJobId] = useState(null)
    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const profile = useSelector(state => state.generalReducer.profile)

    useEffect(() => {
        dispatch(getProfile({}))
    }, [])

    useEffect(() => {
        if(!profile?.id) return
    }, [profile])

    return (
        <>
            <Navbar />
            {
                isApiLoading && <CustomLoader />
            }
            <section className="relative lg:mt-24 mt-[74px]">
                <div className="lg:container container-fluid">
                    <div className="relative shrink-0">
                        <img src={bg5} className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700" alt="" />
                    </div>

                    <div className="md:flex ms-4 -mt-12">
                        <div className="md:w-full">
                            <div className="relative flex items-end">
                                <img src={`${profile?.profilePicture ? `${process.env.REACT_APP_API_BASE_URL}/${profile?.profilePicture}` : image}`} className="h-28 w-28 rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800" alt="" />
                                <div className="ms-4">
                                    <h5 className="text-lg font-semibold">{profile?.User?.firstName}</h5>
                                    <p className="text-slate-400">{profile?.currentPosition}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative mt-12 md:pb-24 pb-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-7">
                            {
                                profile?.coverLetter && <>
                                    <h5 className="text-xl font-semibold">Bio</h5>
                                    <p className="text-slate-400 mt-4">{profile?.coverLetter}</p>
                                </>
                            }

                            {
                                profile?.manatal?.experiences.length ? <>
                                <h4 className="mt-6 text-xl font-semibold">Experience :</h4>
                                <div className="flex mt-6">
                                    <div className="text-slate-400 font-semibold min-w-[80px] text-center">
                                        {new Date(profile?.manatal?.experiences[0].started_at).getFullYear()} - {
                                            profile?.manatal?.experiences[0].ended_at ? 
                                        new Date(profile?.manatal?.experiences[0].ended_at).getFullYear() :
                                            'Present'
                                        }
                                    </div>

                                    <div className="ms-4">
                                        <h5 className="text-lg font-medium mb-0">{profile?.manatal?.experiences[0].position}</h5>
                                        <span className="text-slate-400 company-university">{profile?.manatal?.experiences[0].employer} - {profile?.manatal?.experiences[0].location}</span>
                                        <p className="text-slate-400 mt-2 mb-0">{profile?.manatal?.experiences[0].description}</p>
                                    </div>
                                </div>
                                </> : null
                            }

                            {
                                profile?.manatal?.educations.length ? <>
                                <h4 className="mt-6 text-xl font-semibold">Education :</h4>
                                <div className="flex mt-6">
                                    <div className="text-slate-400 font-semibold min-w-[80px] text-center">
                                        {new Date(profile?.manatal?.educations[0].started_at).getFullYear()} - {
                                            profile?.manatal?.educations[0].ended_at ? 
                                        new Date(profile?.manatal?.educations[0].ended_at).getFullYear() :
                                            'Present'
                                        }
                                    </div>

                                    <div className="ms-4">
                                        <h5 className="text-lg font-medium mb-0">{profile?.manatal?.educations[0].degree}</h5>
                                        <span className="text-slate-400 company-university">{profile?.manatal?.educations[0].school} - {profile?.manatal?.educations[0].location}</span>
                                        <p className="text-slate-400 mt-2 mb-0">{profile?.manatal?.educations[0].description}</p>
                                    </div>
                                </div>
                                </> : null
                            }
                        </div>

                        <div className="lg:col-span-4 md:col-span-5">
                            <div className="bg-slate-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700 p-6 sticky top-20">
                                <h5 className="text-lg font-semibold">Personal Detail</h5>
                                <ul className="list-none mt-4">
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail><span className="text-slate-400 me-3">Email  :</span></span>

                                        <span>{profile?.User?.email}</span>
                                    </li>
                                    {
                                            profile?.dob && <li className="flex justify-between mt-3 items-center font-medium">
                                            <span><Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift><span className="text-slate-400 me-3">D.O.B. :</span></span>

                                            <span>{moment(profile?.dob).format('MMM DD, YYYY')}</span>
                                        </li>
                                    }
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Globe className="h-4 w-4 text-slate-400 me-3 inline"></Globe><span className="text-slate-400 me-3">Country :</span></span>

                                        <span>{profile?.country}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone><span className="text-slate-400 me-3">Mobile :</span></span>

                                        <span>{profile?.manatal?.candidate?.phone_number}</span>
                                    </li>

                                    <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">Resume.pdf</span>
                                        </div>

                                        <Link target='_blank' to={`${process.env.REACT_APP_API_BASE_URL}/${profile?.resumePath}`} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full" download><i className="uil uil-file-download-alt"></i> Download CV</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <ExploreJob />
            </section>
            <Footer />

        </>
    )
}
