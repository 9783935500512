import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import spotify from '../assets/images/company/spotify.png';
import linkedin from '../assets/images/company/linkedin.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import telegram from '../assets/images/company/telegram.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import React from 'react'
import { Link } from "react-router-dom";
import { Bookmark } from 'react-feather';

export default function JobListCompTwo() {
    const JobListCompTwo = [
        {
            image: facebook_logo,
            title: 'Web Designer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'Australia',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: google_logo,
            title: 'Marketing Director',
            jobtype: 'Part Time',
            date: '20th Feb 2023',
            country: 'USA',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: android,
            title: 'App Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'China',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: lenovo_logo,
            title: 'Product Designer',
            jobtype: 'WFH',
            date: '20th Feb 2023',
            country: 'Dubai',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: spotify,
            title: ' C++ Developer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'India',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: linkedin,
            title: 'Php Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'Pakistan',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: circle_logo,
            title: 'Web Designer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'Australia',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: skype,
            title: 'Marketing Director',
            jobtype: 'Part Time',
            date: '20th Feb 2023',
            country: 'USA',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: snapchat,
            title: 'App Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'China',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: shree_logo,
            title: 'Product Designer',
            jobtype: 'WFH',
            date: '20th Feb 2023',
            country: 'Dubai',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: telegram,
            title: 'C++ Developer',
            jobtype: 'Full Time',
            date: '20th Feb 2023',
            country: 'India',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        },
        {
            image: whatsapp,
            title: 'Php Developer',
            jobtype: 'Remote',
            date: '20th Feb 2023',
            country: 'Pakistan',
            price: '$4,000 - $4,500',
            apply: 'Apply Now'
        }
    ]
    return (
        <div className="lg:col-span-8 md:col-span-6">
            <div className="grid grid-cols-1 gap-[30px]">

                {JobListCompTwo.map((item, index) => (
                    <div className="group relative overflow-hidden lg:flex justify-between items-center rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5">
                        <div className="flex items-center">
                            <div
                                className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <img src={item.image} className="h-8 w-8" alt="" />
                            </div>
                            <Link to="/job-detail-three"
                                className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500 ms-3 min-w-[150px]">{item.title}</Link>
                        </div>

                        <div className="lg:block flex justify-between lg:mt-0 mt-4">
                            <span className="block"><span
                                className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">{item.jobtype}</span></span>
                            <span className="block text-slate-400 text-sm md:mt-1 mt-0"><i className="uil uil-clock"></i>{item.date}</span>
                        </div>

                        <div className="lg:block flex justify-between lg:mt-0 mt-2">
                            <span className="text-slate-400"><i className="uil uil-map-marker"></i>{item.country}</span>
                            <span className="block font-semibold lg:mt-1 mt-0">{item.price}</span>
                        </div>

                        <div className="lg:mt-0 mt-4">
                            <Link to="#"
                                className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white lg:relative absolute top-0 end-0 lg:m-0 m-3">
                                <Bookmark className="h-4 w-4"></Bookmark></Link>
                            <Link to="/job-apply"
                                className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full md:w-auto">{item.apply}</Link>
                        </div>

                        <span
                            className="w-24 bg-yellow-400 text-white text-center absolute ltr:-rotate-45 rtl:rotate-45 -start-[30px] top-1"><i
                                className="uil uil-star"></i></span>
                    </div>
                ))}

            </div>

            <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                <div className="md:col-span-12 text-center">
                    <nav aria-label="Page navigation example">
                        <ul className="inline-flex items-center -space-x-px">
                            <li>
                                <Link to="#"
                                    className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                    <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="#"
                                    className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                            </li>
                            <li>
                                <Link to="#"
                                    className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                            </li>
                            <li>
                                <Link to="#" aria-current="page"
                                    className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                            </li>
                            <li>
                                <Link to="#"
                                    className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                            </li>
                            <li>
                                <Link to="#"
                                    className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                            </li>
                            <li>
                                <Link to="#"
                                    className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                    <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}
