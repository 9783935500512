import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { message } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { applyJob, getProfile, loginUser } from '../redux/features/generalSlice';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoader from '../components/CustomLoader';
import { FileText, Image } from 'react-feather';
import CountriesComponent from '../components/CountriesComponent';

export default function ProfileUpdate() {

    const navigate = useNavigate();
    const [applying, setApplying] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation();
    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const user = useSelector(state => state.generalReducer.user)
    const profileRaw = useSelector(state => state.generalReducer.profile);
    const [profile, setProfile] = useState(null)

    const [profileUpdate, setProfileUpdate] = useState(location.search ? false : true)
    const [isJobApplying, setIsJobApplying] = useState(location.search ? true : false)

    const [data, setData] = useState({
        name: "",
        currentPosition: "0",
        email: "",
        phone: "",
        dob: "",
        address: "",
        country: "0",
        gender: "0",
        resumeBase64: "",
        profilePicture: "",
        ppExt: "",
        password: "",
        extension: "",
        employer: "",
        current_position: "",
        experienceLocation: "",
        startedAt: "",
        endedAt: "",
        is_current_employer: false,
        edu_degree: "",
        edu_school: "",
        edu_location: "",
        edu_started_at: "",
        edu_ended_at: "",
        jobId: location.search ? location.search.split('=')[1].split('&')[0] : null
    })

    useEffect(() => {
        if (user)
            dispatch(getProfile({}))
    }, [])

    useEffect(() => {
        if (!profileRaw?.id) return;

        setData({
            ...data,
            name: profileRaw.User.firstName,
            currentPosition: profileRaw.currentPosition,
            email: profileRaw.User.email,
            gender: profileRaw?.manatal?.candidate?.gender || "0",
            phone: profileRaw.User.phone,
            dob: profileRaw.dob ? profileRaw.dob.split('T')[0] : null,
            country: profileRaw.country,
            employer: profileRaw?.manatal?.experiences?.length ? profileRaw.manatal.experiences[0].employer : '',
            current_position: profileRaw?.manatal?.experiences?.length ? profileRaw.manatal.experiences[0].position : '',
            experienceLocation: profileRaw?.manatal?.experiences?.length ? profileRaw.manatal.experiences[0].location : '',
            startedAt: profileRaw?.manatal?.experiences?.length ? profileRaw.manatal.experiences[0].started_at : '',
            endedAt: profileRaw?.manatal?.experiences?.length ? profileRaw.manatal.experiences[0].ended_at : '',
            is_current_employer: profileRaw?.manatal?.experiences?.length ? profileRaw.manatal.experiences[0].is_current_employer : '',
            edu_degree: profileRaw?.manatal?.educations?.length ? profileRaw.manatal.educations[0].degree : '',
            edu_school: profileRaw?.manatal?.educations?.length ? profileRaw.manatal.educations[0].school : '',
            edu_location: profileRaw?.manatal?.educations?.length ? profileRaw.manatal.educations[0].location : '',
            edu_started_at: profileRaw?.manatal?.educations?.length ? profileRaw.manatal.educations[0].started_at : '',
            edu_ended_at: profileRaw?.manatal?.educations?.length ? profileRaw.manatal.educations[0].ended_at : '',
        })
        setProfile(profileRaw)
    }, [profileRaw])

    const apply = async () => {
        if (profileUpdate && data.password && !window.confirm("Are you sure you also want to update your password?"))
            return

        if (!data.name || !data.email || !data.country || !data.phone || !data.currentPosition || !data.employer || !data.current_position || !data.experienceLocation || !data.startedAt || !data.gender || !data.edu_degree || !data.edu_school || !data.edu_location || !data.edu_started_at || !data.edu_ended_at)
            return message.error("Please provide all the required information (*)")

        if (!data.is_current_employer && !data.endedAt)
            return message.error("Please provide all the required information (*)")

        if (profile?.id && !profile.resumePath && !data.resumeBase64)
            return message.error("Please provide all the required information (*)")

        if (!profile?.id && !data.resumeBase64)
            return message.error("Please provide all the required information (*)")

        if (!user && !data.password)
            return message.error("Please provide all the required information (*)")

        setApplying(true);

        const isApplied = await dispatch(applyJob({
            profileUpdate,
            userId: user?.id || undefined,
            full_name: data.name,
            gender: data.gender,
            currentPosition: data.currentPosition,
            email: data.email,
            birth_date: data.dob,
            phone_number: data.phone,
            ppExt: data.ppExt,
            country: data.country,
            extension: data.extension,
            ...(data.resumeBase64 !== "null" && data.resumeBase64 && { resumeBase64: data.resumeBase64 }),
            ...(data.profilePicture !== "null" && data.profilePicture && { profilePicture: data.profilePicture }),
            ...(data.password !== "null" && data.password && { password: data.password }),
            employer: data.employer,
            current_position: data.current_position,
            experienceLocation: data.experienceLocation,
            startedAt: data.startedAt,
            endedAt: data.is_current_employer ? null : data.endedAt,
            is_current_employer: data.is_current_employer,
            edu_degree: data.edu_degree,
            edu_school: data.edu_school,
            edu_location: data.edu_location,
            edu_started_at: data.edu_started_at,
            edu_ended_at: data.edu_ended_at,
            job_id: data.jobId
        }));

        if (isApplied?.payload?.response?.data?.errorMessage)
            return message.error(isApplied?.payload?.response?.data?.errorMessage)

        navigate(`/candidate-detail`);
        message.success(`Your profile is updated successfully`)
    }

    const convertImgToBase64 = async (e, isProfilePicture) => {
        if (!e.target.files.length) return
        const reader = new FileReader();
        const fileExt = e.target.files[0].name.split(".").at(-1);
        if (isProfilePicture) {
            if (fileExt !== 'jpeg' && fileExt !== 'jpg' && fileExt !== 'png') {
                document.getElementById('profile_picture').value = "";
                return message.warning("Only png/jpeg files are allowed")
            }
        } else {
            if (fileExt !== 'pdf') {
                document.getElementById('resume_file').value = "";
                return message.warning("Only pdf files are allowed")
            }
        }
        reader.onloadend = () => {
            if (isProfilePicture)
                setData({ ...data, profilePicture: reader.result.split(",")[1], ppExt: fileExt });
            else
                setData({ ...data, resumeBase64: reader.result.split(",")[1], extension: fileExt });
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    const cancelUpload = async () => {
        const profileTemp = JSON.parse(JSON.stringify(profile));
        profileTemp.resumePath = profileRaw.resumePath
        setProfile(profileTemp)
    }

    const cancelProfilePicture = async () => {
        const profileTemp = JSON.parse(JSON.stringify(profile));
        profileTemp.profilePicture = profileRaw.profilePicture
        setProfile(profileTemp)
    }

    const uploadNew = async () => {
        const profileTemp = JSON.parse(JSON.stringify(profile));
        profileTemp.resumePath = null
        setProfile(profileTemp)
    }

    const uploadNewProfilePicture = async () => {
        const profileTemp = JSON.parse(JSON.stringify(profile));
        profileTemp.profilePicture = null
        setProfile(profileTemp)
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            {
                isApiLoading && <CustomLoader />
            }
            {
                location.search && location.search.split('&position=').length &&
                <>
                    <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                        <div className="absolute inset-0 bg-emerald-900/90"></div>
                        <div className="container">
                            <div className="grid grid-cols-1 text-center mt-10">
                                <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">{decodeURIComponent(location.search.split('&position=')[1])}</h3>

                            </div>
                        </div>

                        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                            <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                                <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">Jobstack</Link></li>
                                <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/jobs">Jobs</Link></li>
                                <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">{user ? 'Job Detail' : 'Sign up'}</li>
                            </ul>
                        </div>
                    </section>
                    <div className="relative">
                        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                            <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </>
            }
            <section className="relative bg-slate-50 dark:bg-slate-800 lg:py-24 py-16">
                <div className="container">
                    <div className="md:flex justify-center">
                        <div className="lg:w-2/4 md:w-2/3">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <form className="text-left">
                                    <div className="grid grid-cols-1">
                                        <div className="mb-4 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Your Name*</label>
                                            <input name="name" value={data.name} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="RegisterName" type="text" className={`form-input border border-slate-100 dark:border-slate-800 mt-1`} placeholder="Harry" />
                                        </div>

                                        {
                                            !isJobApplying && <div className='mb-4 ltr:text-left rtl:text-right'>
                                                <label className="font-semibold">Gender*</label>
                                                <select onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="gender" value={data.gender} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                                    <option disabled selected value={0}>Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Rather not say</option>
                                                </select>
                                            </div>
                                        }

                                        {
                                            !isJobApplying && <div className="mb-4 ltr:text-left rtl:text-right">
                                                <label className="font-semibold" htmlFor="RegisterName">Current Position*</label>
                                                {/*  */}
                                                <select onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="currentPosition" value={data.currentPosition} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                                    <option disabled selected value={0}>Select Current Position</option>
                                                    <option value="Principal/Head/Director">Principal/Head/Director</option>
                                                    <option value="Deputy Head/Vice principal">Deputy Head/Vice principal</option>
                                                    <option value="School head (primary/Secondary)">School head (primary/Secondary)</option>
                                                    <option value="Counselor ">Counselor </option>
                                                    <option value="Coordinator (Academic)">Coordinator (Academic)</option>
                                                    <option value="Early childhood/EYFS">Early childhood/EYFS</option>
                                                    <option value="Primary/K1-5/PYP">Primary/K1-5/PYP</option>
                                                    <option value="Maths ">Maths </option>
                                                    <option value="English">English</option>
                                                    <option value="Biology">Biology</option>
                                                    <option value="Physics">Physics</option>
                                                    <option value="Chemistry">Chemistry</option>
                                                    <option value="History/Social studies/Humanities">History/Social studies/Humanities</option>
                                                    <option value="Languages">Languages</option>
                                                    <option value="PE">PE</option>
                                                    <option value="Design technology">Design technology</option>
                                                    <option value="Music">Music</option>
                                                    <option value="Art">Art</option>
                                                    <option value="IT/ICT">IT/ICT</option>
                                                    <option value="Business/Economics">Business/Economics</option>
                                                    <option value="ESL">ESL</option>

                                                </select>
                                            </div>
                                        }

                                        <div className="mb-4 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="LoginEmail">Email Address* <small style={{ color: 'red' }}>You can not update email address</small> </label>
                                            <input disabled={user} name="email" value={data.email} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="LoginEmail" type="email" className={`form-input border border-slate-100 dark:border-slate-800 mt-1 ${user && 'disabled'}`} placeholder="name@example.com" />
                                        </div>

                                        {
                                            !isJobApplying && <>
                                                <div className='mb-4 ltr:text-left rtl:text-right'>
                                                    <label className="font-semibold">Country*</label>
                                                    <select onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="country" value={data.country} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                                        <option disabled selected value={0}>Select Country</option>
                                                        <CountriesComponent></CountriesComponent>
                                                    </select>
                                                </div>

                                                <div className="mb-4 ltr:text-left rtl:text-right">
                                                    <label className="font-semibold" htmlFor="PhoneNo">Phone No*</label>
                                                    <input onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="phone" value={data.phone} id="PhoneNo" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="+458 854-8965" />
                                                </div>

                                                <div className='mb-4 ltr:text-left rtl:text-right'>
                                                    <div className="mb-2 ltr:text-left rtl:text-right">
                                                        <label htmlFor="comments" className="font-semibold">D.O.B</label>
                                                        <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="dob" value={data.dob} id="dob" type="date" className="form-input border border-slate-100 dark:border-slate-800 mt-1" />
                                                    </div>
                                                </div>
                                                <div className="mb-2 ltr:text-left rtl:text-right" style={{ margin: '10px 0px' }}>
                                                    <label style={{ marginBottom: 20, display: 'block' }} htmlFor="comments" className="font-semibold">Recent Experience*</label>
                                                    <div style={{ fontSize: "12px" }}>
                                                        <div className="mb-2 ltr:text-left rtl:text-right">
                                                            <label htmlFor="comments" className="font-semibold">Employer*</label>
                                                            <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="employer" value={data.employer} id="employer" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Company" />
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Location*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="experienceLocation" value={data.experienceLocation} id="experienceLocation" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="US" />
                                                            </div>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Position*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="current_position" value={data.current_position} id="current_position" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Senior Developer" />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Started*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="startedAt" value={data.startedAt} id="startedAt" type="date" className="form-input border border-slate-100 dark:border-slate-800 mt-1" />
                                                            </div>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Ended*</label>
                                                                <input style={{ fontSize: '12px' }} disabled={data.is_current_employer} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="endedAt" value={data.endedAt} id="endedAt" type="date" className={`form-input border border-slate-100 dark:border-slate-800 mt-1 ${data.is_current_employer && 'disabled'}`} />
                                                                <label htmlFor="experience" className="font-semibold">Currently Employed</label>
                                                                &nbsp;&nbsp;<input value={data.is_current_employer} checked={data.is_current_employer} onChange={(e) => setData({ ...data, [e.target.name]: e.target.checked })} name="is_current_employer" type="checkbox" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-2 ltr:text-left rtl:text-right" style={{ margin: '10px 0px' }}>
                                                    <label style={{ marginBottom: 20, display: 'block' }} htmlFor="comments" className="font-semibold">Education*</label>
                                                    <div style={{ fontSize: "12px" }}>
                                                        <div className="mb-2 ltr:text-left rtl:text-right">
                                                            <label htmlFor="comments" className="font-semibold">Degree*</label>
                                                            <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_degree" value={data.edu_degree} id="edu_degree" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Degree" />
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">School*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_school" value={data.edu_school} id="edu_school" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="School" />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Location*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_location" value={data.edu_location} id="edu_location" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="US" />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Started*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_started_at" value={data.edu_started_at} id="edu_started_at" type="date" className="form-input border border-slate-100 dark:border-slate-800 mt-1" />
                                                            </div>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Ended*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_ended_at" value={data.edu_ended_at} id="edu_ended_at" type="date" className={`form-input border border-slate-100 dark:border-slate-800 mt-1`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            !isApiLoading && profile?.resumePath &&
                                            <div className='row' style={{ marginTop: '10px' }}>
                                                <div className="col-md-12">
                                                    <Link style={{ float: 'left' }} to={`${process.env.REACT_APP_API_BASE_URL}/${profile.resumePath}`} target='_blank'>
                                                        <div className="flex items-center mb-3">
                                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                                            <span className="font-medium ms-2">Resume.pdf</span>
                                                        </div>
                                                    </Link>
                                                    <button type="button" className='btn rounded-md ' style={{ float: 'right' }} onClick={uploadNew}>Change</button>
                                                </div>
                                            </div>
                                        }

                                        {
                                            !isApiLoading && !profile?.resumePath && <div>
                                                <label className="font-semibold" htmlFor="resume_file">Upload Resume*</label>
                                                {
                                                    !user && <small style={{ float: 'right', fontWeight: 'bold' }}>PDF Format</small>
                                                }
                                                {
                                                    user && <button type="button" className='btn rounded-md ' style={{ float: 'right' }} onClick={cancelUpload}>Cancel</button>
                                                }
                                                <input className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1" id="resume_file" accept='application/pdf' type="file" onChange={(e) => convertImgToBase64(e)} />
                                            </div>
                                        }

                                        {
                                            profileUpdate && profile?.profilePicture &&
                                            <div className='row' style={{ marginTop: '10px' }}>
                                                <div className="col-md-12">
                                                    <Link target="_blank" style={{ float: 'left', cursor: 'pointer' }} to={`${process.env.REACT_APP_API_BASE_URL}/${profile.profilePicture}`} target='_blank'>
                                                        <div className="flex items-center mb-3">
                                                            <Image className="h-8 w-8 text-slate-400"></Image>
                                                            <span className="font-medium ms-2">Profile Picture &nbsp;&nbsp;<LinkOutlined /> </span>
                                                        </div>
                                                    </Link>
                                                    <button type="button" className='btn rounded-md ' style={{ float: 'right' }} onClick={uploadNewProfilePicture}>Change</button>
                                                </div>
                                            </div>
                                        }

                                        {
                                            profileUpdate && !profile?.profilePicture && <div style={{ marginTop: '10px' }}>
                                                <label className="font-semibold" htmlFor="resume_file">Profile Picture*</label>
                                                {
                                                    user && <button type="button" className='btn rounded-md ' style={{ float: 'right' }} onClick={cancelProfilePicture}>Cancel</button>
                                                }
                                                <input className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1" id="profile_picture" accept='image/png, image/jpeg' type="file" onChange={(e) => convertImgToBase64(e, true)} />
                                            </div>
                                        }

                                        {
                                            !profileUpdate && !isApiLoading && !isJobApplying &&
                                            <div className=" customStyling mb-4 ltr:text-left rtl:text-right">
                                                <div style={{ marginTop: '20px' }}>
                                                    <label className="font-semibold" htmlFor="profile_picture">Profile Picture</label>
                                                    <small style={{ float: 'right', fontWeight: 'bold' }}>PNG/JPEG Format</small>
                                                    <input className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1" id="profile_picture" accept='image/png, image/jpeg' type="file" onChange={(e) => convertImgToBase64(e, true)} />
                                                </div>
                                            </div>
                                        }

                                        {
                                            !isJobApplying && <div className="customStyling mb-4 ltr:text-left rtl:text-right">
                                                <hr />
                                                <label className="font-semibold" htmlFor="LoginEmail">{user ? 'Update' : 'Create'} Password*</label>
                                                <input name="password" value={data.password} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="passwordToUpdate" type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="********" />
                                                {
                                                    user ? <small>Please provide password only if you want to update</small> : <small>By providing this password, you'll be able to login to the platform and view your applications & profile</small>
                                                }
                                                <hr />
                                            </div>
                                        }

                                        <div>
                                            <button type="button" id="button" name="send" disabled={applying} onClick={apply} className={`btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white ${applying && 'disabled'}`}>{applying && !profileUpdate ? (user ? 'Applying..' : 'Please Wait..') : (user ? (isJobApplying ? 'Apply' : 'Update') : 'Sign up')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
