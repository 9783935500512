import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/logo-light.png';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/features/generalSlice';
import { message } from 'antd';

export default function Login() {

    const navigate = useNavigate();
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const dispatch = useDispatch()
    const [data, setData] = useState({
        email: '',
        password: '',
        role: 'CANDIDATE'
    })

    const signin = async () => {
        if (!data.email || !data.password)
            return message.error("Please provide all the required information (*)")
        setIsLoggingIn(true)
        const isLoggedin = await dispatch(login(data))
        if (!isLoggedin?.payload?.status || ![200, 201].includes(isLoggedin?.payload?.status)) {
            setIsLoggingIn(false)
            return message.error("Invalid Credentials")
        }
        navigate('/candidate-detail')
    }

    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg1.jpg')] bg-no-repeat bg-center bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
            <div className="container">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                    <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                        <div className="p-6">
                            <Link to="/">
                                <img src="logo_dark.png" className="mx-auto h-[24px] block dark:hidden" alt="" />
                                <img src="logo_light.png" className="mx-auto h-[24px] dark:block hidden" alt="" />
                            </Link>
                            <h5 className="my-6 text-xl font-semibold">Login</h5>
                            <form className="text-left">
                                <div className="grid grid-cols-1">
                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginEmail">Email Address*</label>
                                        <input onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="email" type="email" className="form-input mt-3 rounded-md" placeholder="name@example.com" />
                                    </div>

                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginPassword">Password*</label>
                                        <input onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="password" type="password" className="form-input mt-3 rounded-md" placeholder="Password:" />
                                    </div>

                                    <div className="flex justify-between mb-4">
                                        <div className="inline-flex items-center">
                                            <input className="form-checkbox text-emerald-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="Rememberme" />
                                            {/* <label className="form-check-label text-slate-400" htmlFor="Rememberme">Remember me</label> */}
                                        </div>
                                        <p className="text-slate-400 mb-0"><Link to="/reset-password" className="text-slate-400">Forgot password ?</Link></p>
                                    </div>

                                    <div className="mb-4" style={{ display: 'flex' }}>
                                        <Link to={'/'}>
                                            <button type='button' style={{ borderRadius: '0.375rem', marginRight: '5px' }} className='btn'>Back</button>
                                        </Link>
                                        <button type="button" onClick={signin} className={`btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full ${isLoggingIn && 'disabled'}`} disabled={isLoggingIn}>Login / Sign in</button>
                                    </div>

                                    <div className="text-center">
                                        <span className="text-slate-400 me-2">Not a member? </span><Link to="/job-apply" className="text-black dark:text-white font-bold">Sign up</Link>
                                    </div>

                                    {/* <div className="text-center">
                                        <span className="text-slate-400 me-2">Don't have an account ?</span> <Link to="/signup" className="text-black dark:text-white font-bold">Sign Up</Link>
                                    </div> */}
                                </div>
                            </form>
                        </div>

                        <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                            <p className="mb-0 text-gray-400 font-medium">© {(new Date().getFullYear())}{" "} Inbound Educators</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
