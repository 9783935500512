import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/logo-light.png';
import { useDispatch, useSelector } from 'react-redux';
import { FileText } from 'react-feather';
import CountriesComponent from '../../components/CountriesComponent';
import { message } from 'antd';

export default function Signup() {

    const navigate = useNavigate();
    const [applying, setApplying] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation();
    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const user = useSelector(state => state.generalReducer.user)
    const profileRaw = useSelector(state => state.generalReducer.profile);
    const [profile, setProfile] = useState(null)

    const [data, setData] = useState({
        name: "",
        currentPosition: "0",
        email: "",
        phone: "",
        dob: "",
        address: "",
        country: "0",
        gender: "0",
        resumeBase64: "",
        profilePicture: "",
        ppExt: "",
        password: "",
        extension: "",
        employer: "",
        current_position: "",
        experienceLocation: "",
        startedAt: "",
        endedAt: "",
        is_current_employer: false,
        edu_degree: "",
        edu_school: "",
        edu_location: "",
        edu_started_at: "",
        edu_ended_at: ""
    })

    const uploadNew = async () => {
        const profileTemp = JSON.parse(JSON.stringify(profile));
        profileTemp.resumePath = null
        setProfile(profileTemp)
    }

    const convertImgToBase64 = async (e, isProfilePicture) => {
        if (!e.target.files.length) return
        const reader = new FileReader();
        const fileExt = e.target.files[0].name.split(".").at(-1);
        if (isProfilePicture) {
            if (fileExt !== 'jpeg' && fileExt !== 'jpg' && fileExt !== 'png') {
                document.getElementById('profile_picture').value = "";
                return message.warning("Only png/jpeg files are allowed")
            }
        } else {
            if (fileExt !== 'pdf') {
                document.getElementById('resume_file').value = "";
                return message.warning("Only pdf files are allowed")
            }
        }
        reader.onloadend = () => {
            if (isProfilePicture)
                setData({ ...data, profilePicture: reader.result.split(",")[1], ppExt: fileExt });
            else
                setData({ ...data, resumeBase64: reader.result.split(",")[1], extension: fileExt });
        };
        reader.readAsDataURL(e.target.files[0]);
    }

    const apply = async () => {}

    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-center bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
            <div className="container">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                    <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                        <div className="p-6">
                            <Link to="#">
                                <img src={'/logo.png'} className="mx-auto h-[24px] block dark:hidden" alt="" />
                                <img src={'/logo.png'} className="mx-auto h-[24px] dark:block hidden" alt="" />
                            </Link>
                            <h5 className="my-6 text-xl font-semibold">Signup</h5>
                            <form className="text-left">
                                    <div className="grid grid-cols-1">
                                        <div className="mb-4 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Your Name*</label>
                                            <input disabled={user} name="name" value={data.name} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="RegisterName" type="text" className={`form-input border border-slate-100 dark:border-slate-800 mt-1 ${user && 'disabled'}`} placeholder="Harry" />
                                        </div>

                                        {
                                            !user && <div className='mb-4 ltr:text-left rtl:text-right'>
                                                <label className="font-semibold">Gender*</label>
                                                <select onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="gender" value={data.gender} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                                    <option disabled selected value={0}>Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Rather not say</option>
                                                </select>
                                            </div>
                                        }

                                        {
                                            !user && <div className="mb-4 ltr:text-left rtl:text-right">
                                                <label className="font-semibold" htmlFor="RegisterName">Current Position*</label>
                                                {/*  */}
                                                <select onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="currentPosition" value={data.currentPosition} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                                    <option disabled selected value={0}>Select Current Position</option>
                                                    <option value="Principal/Head/Director">Principal/Head/Director</option>
                                                    <option value="Deputy Head/Vice principal">Deputy Head/Vice principal</option>
                                                    <option value="School head (primary/Secondary)">School head (primary/Secondary)</option>
                                                    <option value="Counselor ">Counselor </option>
                                                    <option value="Coordinator (Academic)">Coordinator (Academic)</option>
                                                    <option value="Early childhood/EYFS">Early childhood/EYFS</option>
                                                    <option value="Primary/K1-5/PYP">Primary/K1-5/PYP</option>
                                                    <option value="Maths ">Maths </option>
                                                    <option value="English">English</option>
                                                    <option value="Biology">Biology</option>
                                                    <option value="Physics">Physics</option>
                                                    <option value="Chemistry">Chemistry</option>
                                                    <option value="History/Social studies/Humanities">History/Social studies/Humanities</option>
                                                    <option value="Languages">Languages</option>
                                                    <option value="PE">PE</option>
                                                    <option value="Design technology">Design technology</option>
                                                    <option value="Music">Music</option>
                                                    <option value="Art">Art</option>
                                                    <option value="IT/ICT">IT/ICT</option>
                                                    <option value="Business/Economics">Business/Economics</option>
                                                    <option value="ESL">ESL</option>

                                                </select>
                                            </div>
                                        }

                                        <div className="mb-4 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="LoginEmail">Email Address*</label>
                                            <input disabled={user} name="email" value={data.email} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="LoginEmail" type="email" className={`form-input border border-slate-100 dark:border-slate-800 mt-1 ${user && 'disabled'}`} placeholder="name@example.com" />
                                        </div>

                                        {
                                            !user && <>
                                                <div className='mb-4 ltr:text-left rtl:text-right'>
                                                    <label className="font-semibold">Country*</label>
                                                    <select onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="country" value={data.country} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                                                        <option disabled selected value={0}>Select Country</option>
                                                        <CountriesComponent></CountriesComponent>
                                                    </select>
                                                </div>

                                                <div className="mb-4 ltr:text-left rtl:text-right">
                                                    <label className="font-semibold" htmlFor="PhoneNo">Phone No*</label>
                                                    <input onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="phone" value={data.phone} id="PhoneNo" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="+458 854-8965" />
                                                </div>

                                                <div className='mb-4 ltr:text-left rtl:text-right'>
                                                    <div className="mb-2 ltr:text-left rtl:text-right">
                                                        <label htmlFor="comments" className="font-semibold">D.O.B</label>
                                                        <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="dob" value={data.dob} id="dob" type="date" className="form-input border border-slate-100 dark:border-slate-800 mt-1" />
                                                    </div>
                                                </div>
                                                <div className="mb-2 ltr:text-left rtl:text-right" style={{ margin: '10px 0px' }}>
                                                    <label style={{ marginBottom: 20, display: 'block' }} htmlFor="comments" className="font-semibold">Recent Experience*</label>
                                                    <div style={{ fontSize: "12px" }}>
                                                        <div className="mb-2 ltr:text-left rtl:text-right">
                                                            <label htmlFor="comments" className="font-semibold">Employer*</label>
                                                            <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="employer" value={data.employer} id="employer" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Company" />
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Location*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="experienceLocation" value={data.experienceLocation} id="experienceLocation" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="US" />
                                                            </div>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Position*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="current_position" value={data.current_position} id="current_position" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Senior Developer" />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Started*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="startedAt" value={data.startedAt} id="startedAt" type="date" className="form-input border border-slate-100 dark:border-slate-800 mt-1" />
                                                            </div>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Ended*</label>
                                                                <input style={{ fontSize: '12px' }} disabled={data.is_current_employer} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="endedAt" value={data.endedAt} id="endedAt" type="date" className={`form-input border border-slate-100 dark:border-slate-800 mt-1 ${data.is_current_employer && 'disabled'}`} />
                                                                <label htmlFor="experience" className="font-semibold">Currently Employed</label>
                                                                &nbsp;&nbsp;<input value={data.is_current_employer} onChange={(e) => setData({ ...data, [e.target.name]: e.target.checked })} name="is_current_employer" type="checkbox" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-2 ltr:text-left rtl:text-right" style={{ margin: '10px 0px' }}>
                                                    <label style={{ marginBottom: 20, display: 'block' }} htmlFor="comments" className="font-semibold">Education*</label>
                                                    <div style={{ fontSize: "12px" }}>
                                                        <div className="mb-2 ltr:text-left rtl:text-right">
                                                            <label htmlFor="comments" className="font-semibold">Degree*</label>
                                                            <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_degree" value={data.edu_degree} id="edu_degree" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Degree" />
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">School*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_school" value={data.edu_school} id="edu_school" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="School" />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Location*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_location" value={data.edu_location} id="edu_location" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="US" />
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Started*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_started_at" value={data.edu_started_at} id="edu_started_at" type="date" className="form-input border border-slate-100 dark:border-slate-800 mt-1" />
                                                            </div>
                                                            <div style={{ width: '100%' }} className="mb-2 ltr:text-left rtl:text-right">
                                                                <label htmlFor="comments" className="font-semibold">Ended*</label>
                                                                <input style={{ fontSize: '12px' }} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} name="edu_ended_at" value={data.edu_ended_at} id="edu_ended_at" type="date" className={`form-input border border-slate-100 dark:border-slate-800 mt-1`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className=" customStyling mb-4 ltr:text-left rtl:text-right">

                                            {
                                                !user && <div style={{ marginTop: '20px' }}>
                                                    <label className="font-semibold" htmlFor="profile_picture">Profile Picture</label>
                                                    <small style={{ float: 'right', fontWeight: 'bold' }}>PNG/JPEG Format</small>
                                                    <input className="relative form-input border border-slate-100 dark:border-slate-800 file:h-10 file:-mx-3 file:-my-2 file:cursor-pointer file:rounded-none file:border-0 file:px-3 file:text-neutral-700 bg-clip-padding px-3 py-1.5 file:me-3 mt-1" id="profile_picture" accept='image/png, image/jpeg' type="file" onChange={(e) => convertImgToBase64(e, true)} />
                                                </div>
                                            }

                                            <hr />
                                            {
                                                profile?.resumePath &&
                                                <>
                                                    <Link style={{ float: 'left' }} to={`${process.env.REACT_APP_API_BASE_URL}/${profile.resumePath}`} target='_blank'>
                                                        <div className="flex items-center mb-3">
                                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                                            <span className="font-medium ms-2">Resume.pdf</span>
                                                        </div>
                                                    </Link>
                                                    <button type="button" className='btn rounded-md ' style={{ float: 'right' }} onClick={uploadNew}>Change</button>
                                                </>
                                            }
                                            <hr />

                                        </div>
                                        {
                                            !user && <div className="customStyling mb-4 ltr:text-left rtl:text-right">
                                                <hr />
                                                <label className="font-semibold" htmlFor="LoginEmail">Create Password*</label>
                                                <input name="password" value={data.password} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="LoginEmail" type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="********" />
                                                <small>By providing this password, you'll be able to login to the platform and view your applications & profile</small>
                                                <hr />
                                            </div>
                                        }

                                        <div>
                                            <button type="button" id="button" name="send" disabled={applying} onClick={apply} className={`btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white ${applying && 'disabled'}`}>{applying ? (user ? 'Applying..' : 'Please Wait..') : (user ? 'Apply' : 'Sign up')}</button>
                                        </div>
                                    </div>
                                </form>
                            <form className="text-left">
                                <div className="grid grid-cols-1">
                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="RegisterName">Your Name:</label>
                                        <input id="RegisterName" type="text" className="form-input mt-3 rounded-md" placeholder="Harry" />
                                    </div>

                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                        <input id="LoginEmail" type="email" className="form-input mt-3 rounded-md" placeholder="name@example.com" />
                                    </div>

                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                        <input id="LoginPassword" type="password" className="form-input mt-3 rounded-md" placeholder="Password:" />
                                    </div>

                                    <div className="mb-4">
                                        <div className="flex items-center w-full mb-0">
                                            <input className="form-checkbox text-emerald-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="AcceptT&C" />
                                            <label className="form-check-label text-slate-400" htmlFor="AcceptT&C">I Accept <Link to="#" className="text-emerald-600">Terms And Condition</Link></label>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <input type="submit" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value="Register" />
                                    </div>

                                    <div className="text-center">
                                        <span className="text-slate-400 me-2">Already have an account ? </span> <Link to="/login" className="text-black dark:text-white font-bold">Sign in</Link>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                            <p className="mb-0 text-gray-400 font-medium">© {(new Date().getFullYear())}{" "} Jobstack. Designed by <Link to="https://shreethemes.in/" target="_blank" className="text-reset">Shreethemes</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
