import spotify from '../assets/images/company/spotify.png';
import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import { Link } from "react-router-dom";

export default function FindBestCompanies() {
    const [isOpen, setOpen] = useState(false)
    const FindBestCompanies = [
        {
            brandimage: spotify,
            image: 'spotify ',
            title: 'Spotify',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Australia',
            job: '6 Jobs'
        },
        {
            brandimage: facebook_logo,
            image: 'facebook_logo',
            title: 'Facebook',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'USA',
            job: '6 Jobs'
        },
        {
            brandimage: google_logo,
            image: 'google_logo',
            title: 'Google',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'China',
            job: '6 Jobs'
        },
        {
            brandimage: android,
            image: 'android',
            title: 'Android',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Dubai',
            job: '6 Jobs'
        },
        {
            brandimage: lenovo_logo,
            image: 'lenovo_logo',
            title: 'Lenovo',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Pakistan',
            job: '6 Jobs'
        },
        {
            brandimage: shree_logo,
            image: 'shree_logo',
            title: 'Shreethemes',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'India',
            job: '6 Jobs'
        },
        {
            brandimage: skype,
            image: 'skype',
            title: 'Skype',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Rush',
            job: '6 Jobs'
        },
        {
            brandimage: snapchat,
            image: 'snapchat',
            title: 'Snapchat',
            deta: 'Digital Marketing Solutions for Tomorrow',
            country: 'Turkey',
            job: '6 Jobs'
        }
    ];
    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} />

            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Find Best Companies</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {FindBestCompanies.map((item, index) => (
                        <div className="group relative p-6 rounded-md shadow dark:shadow-gray-700 mt-6" key={index}>
                            <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-700 rounded-md relative -mt-12">
                                <img src={item.brandimage} className="h-8 w-8" alt="" />
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="text-lg hover:text-emerald-600 font-semibold">{item.title}</Link>
                                <p className="text-slate-400 mt-2">{item.deta}</p>
                            </div>

                            <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700 flex justify-between">
                                <span className="text-slate-400"><i className="uil uil-map-marker"></i> {item.country}</span>
                                <span className="block font-semibold text-emerald-600">{item.job}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
                    <div className="md:col-span-12 text-center">
                        <Link to="#" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">See More Companies <i className="uil uil-arrow-right align-middle"></i></Link>
                    </div>
                </div>
            </div>

        </>
    )
}


