import React from 'react'
import { HelpCircle } from 'react-feather'


export default function QuesAnswer() {
    const QuesAnswerData = [
        {
            question: 'Is there a fee to become a member of Inbound Educator?',
            answer: 'No fee is charged to an applicant at any stage of the process.',
        },
        {
            question: 'Can I withdraw from a job vacancy that I have applied to?',
            answer: 'You can withdraw from a vacancy that you have applied for by sending an email to Inbound Educator.',
        },
        {
            question: 'How will I be kept informed of the progress of my job application?',
            answer: 'Once you register then you will receive an email from Inbound Educator to confirm your registration. Every time you apply for a vacancy and for every step afterwards you will be kept informed by Inbound Educator. If you have any questions then we can arrange for one of our team to contact you personally.',
        },
        {
            question: 'How can I find out more about the school or the country where the school is situated?',
            answer: 'You can contact one of our team who will be more than pleased to discuss these issues with you.',
        },
        {
            question: 'What are the steps in applying for a vacancy?',
            answer: 'After registering you can log-in to our portal and  will then be able to browse all the vacancies that are on offer and apply to any that are of interest. Once you apply you will then receive another email from Inbound Educator to acknowledge receipt of the application. It will explain that it is now under process and if successful the application will be sent to the relevant school. References may be taken by Inbound Educator at this stage. The school will contact the applicants directly if they wish to interview them, and the decision on who is hired will be made by them.',
        },
        {
            question: 'Will Inbound Educator ask for references?',
            answer: 'Inbound Educator will endeavor to obtain references to form part of the documentation that is sent to the school. This will speed up the screening process, which will allow schools to quickly shortlist and interview candidates',
        },
        {
            question: "How can I contact Inbound Educators for more information?",
            answer: "Please email <a href='mailto:info@inboundeducator.com'>info@inboundeducator.com</a>"
        }
    ]
    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Questions & Answers</h3>

                {/* <p className="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p> */}
            </div>


            <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                {QuesAnswerData.map((item, index) => (
                    <div className="flex" key={index}>
                        <HelpCircle className="h-8 text-emerald-600 me-3" ></HelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-lg font-semibold">{item.question}<span className="text-emerald-600"></span> </h5>
                            <p className="text-slate-400" dangerouslySetInnerHTML={{__html: item.answer}}></p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

