import React, { useState } from 'react'
import ab01 from '../assets/images/about/ab01.jpg';
import ab02 from '../assets/images/about/ab02.jpg';
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import { Link } from "react-router-dom";

export default function MillionsJob() {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} />
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-5 md:col-span-6">
                    <div className="relative">
                        <div className="relative" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={ab01} className="lg:w-[400px] w-[280px] rounded-md shadow dark:shadow-gray-700" alt="" />
                        </div>
                    </div>
                </div>

                <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                    <div className="lg:ms-5">
                        <h3 className="mb-6 md:text-[26px] text-2xl md:leading-normal leading-normal font-semibold">Thousands of jobs. <br /> Find the one that's right for you.</h3>
                        <p className="text-slate-400 max-w-xl">
                            Set up by two former heads of schools with many years of experience, Inbound Educator was designed to connect schools with qualified and experienced teachers. Our platform streamlines the recruitment process, making it easier for both schools and teachers to find the right fit.
                        </p>
                        <br />
                        <p className="text-slate-400 max-w-xl">
                            For schools, Inbound Educator offers a wide pool of talented teachers who have been vetted for their qualifications and experience. Our advanced search engine allows schools to filter their search based on skills, experience, location, and availability. This saves schools time and effort in the hiring process, ensuring they find the right teachers for their needs.
                        </p>
                        <br />
                        <p className="text-slate-400 max-w-xl">
                            For teachers, Inbound Educator offers a user-friendly platform to find job opportunities that fit their qualifications, experience, and preferences. Our matching algorithm recommends jobs based on the teachers' skills, making it easier for them to find the right job in the right place.
                        </p>
                        <br />
                        <p className="text-slate-400 max-w-xl">
                            At Inbound Educator, we're committed to providing schools and teachers with a hassle-free recruitment process. Our platform is designed to ensure the best match between schools and teachers, resulting in better academic outcomes for students.
                        </p>
                        <br />
                        <p className="text-slate-400 max-w-xl">
                            For more information please use the links below.
                        </p>
                        {/* <p className="text-slate-400 max-w-xl">Inbound Educator is a specialised International teacher recruitment company which leverages on decades of international school leadership experience of our team. Inbound Educator understand the importance of a ‘good fit’. This is not only based on country and package, but understanding the outlook and values of both the school and candidate. </p>
                        <br />
                        <p className="text-slate-400 max-w-xl"> Inbound Educator uses advanced AI to engage, manage and source top quality candidates, with personal support and interaction to understand and support both schools and teachers through their search.</p> */}
                    </div>
                </div>
            </div>
        </>
    )
}
