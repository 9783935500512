import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import spotify from '../assets/images/company/spotify.png';
import linkedin from '../assets/images/company/linkedin.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import telegram from '../assets/images/company/telegram.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Bookmark } from 'react-feather';

export default function JobGridsFourComp({jobs, limit}) {
    const [JobGridsFourComp, setJobGridsFourComp] = useState([]);
    useEffect(() => {
        if(!jobs?.length) return
        const jobsTemp = [];
        jobs.forEach((job, idx) => {
            if(limit && idx > (limit-1)) return
            jobsTemp.push({
                id: job.id,
                clientName: job.client.name,
                clientLogo: job.client.logo,
                title: job.position_name,
                headline: job.position_name,
                detail: job.description,
                jobtype: formatString(job.contract_details),
                amount: job.salary_min ? `${job.currency}${job.salary_min} - ${job.currency}${job.salary_max}` : '',
                country: job.address ? ` ${job.address}` : null
            })
        });
        setJobGridsFourComp(jobsTemp)
    }, [jobs])

    function formatString(str) {
      const words = str.toLowerCase().split('_');
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    }

    return (
        <>
            <div className="container mt-10" id="jobsListingContainer">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                    {JobGridsFourComp.map((item, index) => (
                        <div className="group p-6 rounded-lg border border-emerald-600/20 dark:border-emerald-600/40 bg-white dark:bg-slate-900 hover:bg-emerald-600/[0.02] hover:dark:bg-emerald-600/5 hover:shadow-md hover:shadow-emerald-600/5 transition-all duration-500" key={index} >
                            <div className="flex justify-between items-start">
                                <div>
                                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full mb-2">
                                        <img src={item.clientLogo} className="h-8 w-8" alt="" />
                                    </div>
                                    <Link to={`/job-detail-one?jobId=${item.id}`} className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500">{item.clientName}</Link>
                                </div>

                                <div className="flex items-center">
                                    {/* <Link to="#" className="text-slate-100 dark:text-slate-700 focus:text-red-600 dark:focus:text-red-600 hover:text-red-600 dark:hover:text-red-600 text-2xl"><i className="mdi mdi-heart"></i></Link>
                                    <Link to="#" className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white ms-1"><Bookmark className="h-4 w-4"></Bookmark></Link> */}
                                    <Link to={`/job-detail-one?jobId=${item.id}`} className="btn btn-icon rounded-full bg-emerald-600/5 group-hover:bg-emerald-600 border-emerald-600/10 text-emerald-600 group-hover:text-white ms-1"><i className="uil uil-arrow-up-right"></i></Link>
                                </div>
                            </div>

                            <div className="mt-3 customStyling">
                                <Link to={`/job-detail-one?jobId=${item.id}`} className="text-xl hover:text-emerald-600 font-semibold transition-all duration-500">{item.headline}</Link>
                                <p id='h3Container' style={{height: '90px', overflowY: 'hidden', lineBreak: 'anywhere'}} dangerouslySetInnerHTML={{ __html: item.detail }} className="mt-2"></p>

                                <div>
                                    {/* <Link to={`/job-detail-one?jobId=${item.id}`}>
                                        <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">More Details</span>
                                    </Link> */}
                                    {item.country && <Link to="#">
                                        <span className="bg-emerald-600/5 hover:bg-emerald-600/20 dark:bg-emerald-600/10 hover:dark:bg-emerald-600/30 inline-block text-emerald-600 px-4 text-[14px] font-medium rounded-full mt-2 transition-all duration-500"><i className="uil uil-map-marker"></i> {item.country}</span>
                                    </Link>}
                                    {
                                        item.amount && <Link to="#">
                                        <span className="bg-purple-600/5 hover:bg-purple-600/20 dark:bg-purple-600/10 hover:dark:bg-purple-600/30 inline-block text-purple-600 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">{item.amount}</span>
                                    </Link>
                                    }
                                    <Link to="#">
                                        <span className="bg-orange-500/5 hover:bg-orange-500/20 dark:bg-orange-500/10 hover:dark:bg-orange-500/30 inline-block text-orange-500 px-4 text-[14px] font-medium rounded-full mt-2 me-1 transition-all duration-500">{item.jobtype}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

                {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                                </li>
                                <li>
                                    <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> */}
            </div>
        </>
    )
}
