import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Link, useLocation } from "react-router-dom";
import { Clock, DollarSign, MapPin, Monitor, UserCheck } from 'react-feather';
import { getJobs } from '../../redux/features/generalSlice';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoader from '../../components/CustomLoader'
import moment from "moment";
import ExploreJob from '../../components/Explore-job';

export default function JobDetailOne() {

    const location = useLocation();
    const dispatch = useDispatch();
    const [jobId, setJobId] = useState(null)
    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const jobDetails = useSelector(state => state.generalReducer.jobDetails)
    const user = useSelector(state => state.generalReducer.user)

    useEffect(() => {
        setJobId(location.search.split('=')[1])
    }, [])

    useEffect(() => {
        if (!jobId) return
        getJobDetails(jobId)
    }, [jobId])

    useEffect(() => {
        if (!jobDetails?.id) return
        console.log(jobDetails)
    }, [jobDetails])

    const getJobDetails = async (jobId) => {
        await dispatch(getJobs({ jobId }))
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">{jobDetails?.position_name || '...'}</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/jobs">Jobs</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Job Detail</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    {
                        isApiLoading &&
                        <CustomLoader tipText="Loading Job Details.." />
                    }
                    {
                        jobDetails?.id && <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                            <div className="lg:col-span-4 md:col-span-6">
                                <div className="shadow dark:shadow-gray-700 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                    <div className="p-6">
                                        <h5 className="text-lg font-semibold">Job Information</h5>
                                    </div>
                                    <div className="p-6 border-t border-slate-100 dark:border-t-gray-700">
                                        <ul className="list-none">
                                            <li className="flex items-center">
                                                <UserCheck className="h-5 w-5"></UserCheck>

                                                <div className="ms-4">
                                                    <p className="font-medium">Employee Type:</p>
                                                    <span className="text-emerald-600 font-medium text-sm">{jobDetails.contract_details === 'full_time' ? 'Full Time' : 'Contractual'}
                                                    </span>
                                                </div>
                                            </li>

                                            <li className="flex items-center mt-3">
                                                <MapPin className="h-5 w-5"></MapPin>

                                                <div className="ms-4">
                                                    <p className="font-medium">Location:</p>
                                                    <span className="text-emerald-600 font-medium text-sm">{jobDetails.address}</span>
                                                </div>
                                            </li>

                                            <li className="flex items-center mt-3">
                                                <Monitor className="h-5 w-5"></Monitor>

                                                <div className="ms-4">
                                                    <p className="font-medium">Job Type:</p>
                                                    <span className="text-emerald-600 font-medium text-sm">{jobDetails.position_name}</span>
                                                </div>
                                            </li>

                                            <li className="flex items-center mt-3">
                                                <DollarSign className="h-5 w-5"></DollarSign>

                                                <div className="ms-4">
                                                    <p className="font-medium">Salary:</p>
                                                    <span className="text-emerald-600 font-medium text-sm">{jobDetails.currency}{jobDetails.salary_min} - {jobDetails.currency}{jobDetails.salary_max}</span>
                                                </div>
                                            </li>

                                            <li className="flex items-center mt-3">
                                                <Clock className="h-5 w-5"></Clock>

                                                <div className="ms-4">
                                                    <p className="font-medium">Date posted:</p>
                                                    <span className="text-emerald-600 font-medium text-sm">{moment(jobDetails.created_at).format("Do MMM, YYYY")}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="customStyling lg:col-span-8 md:col-span-6">
                                <div dangerouslySetInnerHTML={{ __html: jobDetails.description }}></div>
                                <div className="mt-5">
                                    <Link to={`/job-apply?jobId=${jobId}&position=${jobDetails.position_name}`} className={`btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full md:w-auto ${user && jobDetails.isApplied ? 'disabled' : ''}`}>{user ? (jobDetails.isApplied ? 'Applied' : 'Apply') : 'Sign up to Apply'}</Link>
                                </div>
                            </div>

                        </div>
                    }
                </div>
                {/* <JobDetailComp /> */}
                <ExploreJob />
            </section>
            <Footer />
        </>
    )
}