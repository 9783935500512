import { configureStore } from "@reduxjs/toolkit";
import generalReducer from './features/generalSlice';

export const store = configureStore({
    reducer: {
        generalReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;