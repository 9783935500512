// home three //

import React from 'react'
import { Link } from 'react-router-dom';

export default function Works() {
    const home = [
        {
            title: 'Register',
            deta: 'Simply register by entering key information and upload your CV',
            icon: '/Register.png'
        },
        {
            title: 'Apply',
            deta: 'Once registered you will have a unique log-in to our portal where you can apply to as many jobs as you like, with a click of a button',
            icon: '/Apply.png'
        },
        {
            title: 'Consult',
            deta: 'Once you have registered and applied, one of our dedicated team members will arrange a video call to learn more about your skills, interest and personality to identify the best role for you. We will also conduct pre-interviews when selected by our partner school.',
            icon: '/Consult.png'
        },
        {
            title: 'Support',
            deta: 'You will be assigned a team member who will support you through the whole recruitment process.',
            icon: '/Support.png'
        }
    ];

    return (

        <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">How It Works</h3>

                <p className="text-slate-400 max-w-xl mx-auto">Through our free and user friendly system, you can browse and apply to numerous positions with schools all over the globe at the click of a button!</p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-6 gap-[30px]">
                {home.map((item, index) => (
                    <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 transition duration-500 rounded-2xl mt-6 text-center" key={index}>
                        <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                            {/* <i className="uil uil-airplay"></i> */}
                            <img src={item.icon} />
                        </div>
                        <div className="content mt-7" >
                            <Link to="#" className="title h5 text-lg font-semibold hover:text-emerald-600">{item.title}</Link>
                            <p className="text-slate-400 mt-3">{item.deta}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )
}