import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Works from '../../components/How-Work'
import QuesAnswer from '../../components/Question-Answer'
import Footer from '../../components/Footer'
import { Link, useNavigate } from "react-router-dom";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from '../../redux/features/generalSlice';
import CustomLoader from '../../components/CustomLoader';
import JobGridsFourComp from '../../components/job-grids-four-comp';
import CountriesComponent from '../../components/CountriesComponent';
import ExploreJob from '../../components/Explore-job'

export default function IndexSeven() {
    const navigate = useNavigate()

    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const jobs = useSelector(state => state.generalReducer.jobs)
    const dispatch = useDispatch();

    useEffect(() => {
        if (jobs && jobs?.length) return
        dispatch(getJobs({}))
    }, [])

    async function searchJob() {
        const keyWord = document.getElementById('keywordSearchHomepage').value !== '' ? document.getElementById('keywordSearchHomepage').value.toLowerCase() : null;
        const country = document.getElementById('countrySearchHomepage').value !== 0 ? document.getElementById('countrySearchHomepage').value : null;
        const jobType = document.getElementById('jobTypeSearchHomepage').value !== 0 ? document.getElementById('jobTypeSearchHomepage').value.toLowerCase() : null;
        navigate(`/jobs?keyWord=${keyWord}&country=${country}&jobType=${jobType}`)
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative flex py-36 md:h-screen h-auto items-center bg-top bg-no-repeat bg-cover">
                <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
                    {/* <iframe title="iframe-1" src="https://player.vimeo.com/video/354421650?background=1&autoplay=1&loop=1&byline=0&title=0" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]"></iframe> */}

                    {/* <iframe title="iframe-2" src="https://www.youtube.com/embed/JsNvHJsufhI?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1" className=""></iframe> */}

                    <video className="sm:block hidden absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2  -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw]" muted autoPlay loop>
                        <source src="/video.mp4" type="video/mp4" />
                    </video>

                </div>
                <div className="absolute inset-0 bg-emerald-900/80"></div>
                <div className="container z-1">
                    <div className="grid grid-cols-1 text-center mt-10 relative">
                        <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold text-white">Got Talent ? <br /> Meet Opportunity</h4>
                        <p className="text-white/50 text-lg max-w-xl mx-auto">Find exciting professional and lifestyle opportunities that will start the next adventure in your life as an international educator!</p>

                        <div className="d-flex" id="reserve-form">
                            <div className="md:w-5/6 mx-auto">
                                <div className="lg:col-span-10 mt-8">
                                    <div className="bg-white dark:bg-slate-900 border-0 shadow rounded-md p-3">
                                        <form action="#">
                                            <div className="registration-form text-dark text-start">
                                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                                    <div className="filter-search-form relative filter-border">
                                                        <i className="uil uil-briefcase-alt icons"></i>
                                                        <input name="name" id="keywordSearchHomepage" type="text" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" placeholder="Search your Keywords" />
                                                    </div>

                                                    <div className="filter-search-form relative filter-border">
                                                        <i className="uil uil-map-marker icons"></i>
                                                        <select id="countrySearchHomepage" className='form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0' defaultValue="0">
                                                            <option value="0">All</option>
                                                            <CountriesComponent></CountriesComponent>
                                                        </select>

                                                    </div>

                                                    <div className="filter-search-form relative filter-border" style={{display: 'none'}}>
                                                        <i className="uil uil-briefcase-alt icons"></i>
                                                        <select id="jobTypeSearchHomepage" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" defaultValue={"0"}>
                                                            <option value="0">All</option>
                                                            <option value="full_time">Full Time</option>
                                                            <option value="part_time">Part Time</option>
                                                            <option value="freelance">Freelance</option>
                                                            <option value="temporary">Temporary</option>
                                                            <option value="internship">Internship</option>
                                                            <option value="apprenticeship">Apprenticeship</option>
                                                            <option value="contractor">Contractor</option>
                                                            <option value="consultancy">Consultancy</option>
                                                        </select>

                                                    </div>

                                                    <input onClick={searchJob} id="search" name="search" style={{ height: '60px', cursor: 'pointer' }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100" defaultValue="Search" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="mt-4">
                            <span className="text-white/60"><span className="text-white">Popular Searches :</span> Designer, Developer, Web, IOS, PHP Senior Engineer</span>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <Works />
                {/* <BrowseByCategories /> */}
            </section>
            <section className="py-24 w-full table relative bg-center bg-no-repeat bg-cover jarallax" data-jarallax data-speed="0.5" style={{backgroundImage: 'url(/1.jpg)'}}>
                <div className="absolute inset-0 bg-slate-900/40"></div>
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div>
                            <h5 className="text-xl font-semibold text-white">Register as a Candidate</h5>

                            <ul className="list-none text-white/50 mt-4">
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> Enter basic information</li>
                                <li className="mb-1 flex" style={{marginLeft: '0px'}}><i className="uil uil-check-circle text-white text-xl me-2"></i> Upload current C.V.</li>
                            </ul>

                            <div className="mt-4">
                                <Link to="/job-apply" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full">Sign up</Link>
                            </div>
                        </div>

                        <div>
                            <h5 className="text-xl font-semibold text-white">Register as School Partner</h5>

                            <ul className="list-none text-white/50 mt-4">
                                <li className="mb-1 flex"><i className="uil uil-check-circle text-white text-xl me-2"></i> Complete information request</li>
                                <li className="mb-1 flex" style={{marginLeft: '0px'}}><i className="uil uil-check-circle text-white text-xl me-2"></i> Conference call/site visit with senior consultant</li>
                                <li className="mb-1 flex" style={{marginLeft: '0px'}}><i className="uil uil-check-circle text-white text-xl me-2"></i> List vacancies</li>
                            </ul>

                            <div className="mt-4">
                                <Link to="/contact" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-full">Register Interest</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="relative md:py-24 py-16">

                {
                    isApiLoading ?
                        <CustomLoader tipText="Loading Job Details.." /> :

                        <div className="">
                            <div className="grid grid-cols-1 pb-8 text-center">
                                <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Popular Jobs</h3>
                                <p className="text-slate-400 max-w-xl mx-auto">Browse our top opportunities around the globe with our partner schools</p>
                            </div>
                            <JobGridsFourComp limit="6" jobs={jobs} />

                            <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                                <div className="md:col-span-12 text-center">
                                    <Link to="/jobs" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">See More Jobs <i className="uil uil-arrow-right align-middle"></i></Link>
                                </div>
                            </div>
                        </div>

                }

                <QuesAnswer />

                <ExploreJob />

                {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="yba7hPeTSjk" onClose={() => setOpen(false)} /> */}
            </section>
            <Footer />
        </>
    )
}
