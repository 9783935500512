import { Spin } from "antd";
import React from "react";

function CustomLoader({ tipText = "Uploading.." }) {
    return (
        <div style={{
            "position": "fixed",
            "top": "0",
            "left": "0",
            "zIndex": "99",
            "background": "#ffffff8f",
            "width": "100%",
            "height": "100%",
            "textAlign": "center",
            "paddingTop": "300px",
        }}>
            <Spin style={{ fontWeight: 'bold' }} tip={tipText} size="large" />
        </div>
    );
}

export default CustomLoader;
