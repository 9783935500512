
import React from 'react'
import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import linkedin from '../assets/images/company/linkedin.png';
import skype from '../assets/images/company/skype.png';
import spotify from '../assets/images/company/spotify.png';
import telegram from '../assets/images/company/telegram.png';
import { Link } from "react-router-dom";


export default function PopularJobsThree() {
    const PopularJobsThree = [
        {
            brandimage: facebook_logo,
            title: 'Software Engineering',
            Day: 'Posted 3 Days ago',
            Time: 'Full Time',
            price: '$950 - $1100/mo',
            image: 'facebook_logo',
            name: 'Facebook Ltd.',
            country: 'Australia'
        },
        {
            brandimage: google_logo,
            title: 'Web Developer',
            Day: 'Posted 3 Days ago',
            Time: 'Remote',
            price: '$2500 - $2600/mo',
            image: 'google_logo',
            name: 'Google Ltd.',
            country: 'America'
        },
        {
            brandimage: linkedin,
            title: 'UX/UI Designer',
            Day: 'Posted 3 Days ago',
            Time: 'Freelance',
            price: ' $3500 - $3600/mo',
            image: 'linkedin',
            name: 'Linkedin Ltd.',
            country: 'Canada'
        },
        {
            brandimage: skype,
            title: 'Human Resource(HR)',
            Day: 'Posted 3 Days ago',
            Time: 'Part Time',
            price: '$2000 - $2500/mo',
            image: 'skype',
            name: 'Skype Ltd.',
            country: 'UK'
        },
        {
            brandimage: spotify,
            title: 'Web Designer',
            Day: 'Posted 3 Days ago',
            Time: 'Full Time',
            price: '$1500 - $1600/mo',
            image: 'spotify',
            name: 'Spotify Ltd.',
            country: 'China'
        },
        {
            brandimage: telegram,
            title: 'Graphic Designer',
            Day: 'Posted 3 Days ago',
            Time: 'Part time',
            price: ' $500 - $600/mo',
            image: 'telegram',
            name: 'Telegram Ltd.',
            country: 'India'
        }
    ];

    return (
        <div>

            <div className="container md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Popular Jobs</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">

                    {PopularJobsThree.map((item, index) => (
                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800" key={index} >
                            <div className="p-6">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-emerald-600">{item.title}</Link>
                                <p className="text-slate-400 mt-2"><i className="uil uil-clock text-emerald-600"></i>{item.Day}</p>

                                <div className="flex justify-between items-center mt-4">
                                    <span className="bg-emerald-600/5 text-emerald-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">{item.Time}</span>

                                    <p className="text-slate-400"><i className="uil uil-usd-circle text-emerald-600"></i>{item.price}</p>
                                </div>
                            </div>

                            <div className="flex items-center p-6 border-t border-gray-100 dark:border-gray-700">
                                <img src={item.brandimage} className="h-12 w-12 shadow-md dark:shadow-gray-800 rounded-md p-2 bg-white dark:bg-slate-900" alt="" />

                                <div className="ms-3">
                                    <h6 className="mb-0 font-semibold text-base">{item.name}</h6>
                                    <span className="text-slate-400 text-sm">{item.country}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <Link to="/job-grid-two" className="btn btn-link text-slate-400 hover:text-emerald-600 after:bg-emerald-600 duration-500 ease-in-out">See More Jobs <i className="uil uil-arrow-right align-middle"></i></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


