import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

function RouteGuard({ children }) {
    const { pathname } = useLocation();
    const router = useNavigate();
    const [authorized, setAuthorized] = useState(false);

    const user = useSelector(s => s.generalReducer.user)

    useEffect(() => {
        validate(pathname, user)
    }, [pathname, user]);

    const validate = async (path, user) => {
        if(!user?.id && ['/candidate-detail'].includes(path)){
            router('/login')
            return setAuthorized(true)
        }else if(user?.id && ['/login'].includes(path)){
            router('/candidate-detail')
            return setAuthorized(true)
        }
        setAuthorized(true)
    }

    return <>
        {
            authorized && children
        }
    </>;
};

export default RouteGuard;