import logo_light from '../assets/images/logo-light.png';
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";

import logo_icon_40 from '../assets/images/logo-icon-40.png';
import logo_icon_40_white from '../assets/images/logo-icon-40-white.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
import image from '../assets/images/team/01.jpg';
import { Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/features/generalSlice';

export default function Navbar(props) {
    const { navClass, topnavClass, isContainerFluid } = props;
    const [isOpen, setMenu] = useState(true);
    const navigate = useNavigate()
    const user = useSelector(state => state.generalReducer.user)

    window.addEventListener("scroll", windowScroll);
    useEffect(() => {
        activateMenu();
    }, []);

    const dispatch = useDispatch()

    function logout() {
        dispatch(logoutUser());
        navigate('/')
    }

    function cancel() { }

    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }
    return (
        <nav id="topnav" className={`defaultscroll is-sticky ${topnavClass}`}>
            <div className={`${isContainerFluid === true ? 'container-fluid md:px-8 px-3' : 'container'}`}>
                <Link className="logo" to="/">

                    <div className="block sm:hidden">
                        <img src="/logo_dark.png" className="h-10 inline-block dark:hidden" alt="" />
                        <img src="/logo_light.png" className="h-10 hidden dark:inline-block" alt="" />
                    </div>

                    {navClass && navClass.includes('nav-light') ?

                        <div className="sm:block hidden">
                            <span className="inline-block dark:hidden">
                                <img src="/logo_dark.png" className="h-[30px] l-dark" alt="web" />
                                <img src="/logo_light.png" className="h-[30px] l-light" alt="web" />
                            </span>
                        </div> :
                        <div className="sm:block hidden">
                            <img src="/logo_dark.png" className="h-[24px] inline-block dark:hidden" alt="mob" />
                            <img src="/logo_light.png" className="h-[24px] hidden dark:inline-block" alt="mob" />
                        </div>
                    }
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <!-- End Mobile Toggle --> */}
                <ul className="buy-button list-none mb-0">
                    <li className="inline-block mb-0">
                        <div className="relative top-[3px]">
                            <i className="uil uil-search text-lg absolute top-[3px] end-3" />
                            <input
                                type="text"
                                className="form-input h-9 pe-10 rounded-3xl sm:w-44 w-36 border-gray-100 dark:border-slate-800 bg-white dark:bg-slate-900"
                                name="s"
                                id="searchItem"
                                placeholder="Search..."
                            />
                        </div>
                    </li>
                    {
                        user && <li className="inline-block ps-1 mb-0">
                            <span to="/candidate-detail" className="btn btn-icon rounded-full bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white" to=""><Link to="/candidate-detail"><img src={`${user?.profilePicture ? `${process.env.REACT_APP_API_BASE_URL}/${user?.profilePicture}` : image}`} className="rounded-full" alt="" /></Link></span>
                        </li>
                    }
                </ul>
                {/* <!--Login button Start--> */}
                <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>
                    <ul className={`navigation-menu ${navClass}`}>
                        <li className={`has-submenu parent-menu-item ${window.location.pathname == '/' && 'active'}`}>
                            <Link to="/">Home</Link>
                        </li>

                        <li className={`has-submenu parent-menu-item ${window.location.pathname == '/aboutus' && 'active'}`}>
                            <Link to="/aboutus">About Us</Link>
                        </li>
                        <li className={`has-submenu parent-menu-item ${(window.location.pathname == '/jobs' || window.location.pathname == '/job-detail-one' || window.location.pathname == '/job-apply') && 'active'}`}>
                            <Link to="/jobs">Jobs</Link>
                        </li>
                        <li className={`has-submenu parent-menu-item ${(window.location.pathname == '/our-schools' || window.location.pathname == '/client-detail') && 'active'}`}>
                            <Link to="/our-schools">Our Schools</Link>
                        </li>
                        <li className={`has-submenu parent-menu-item ${window.location.pathname == '/contact' && 'active'}`}>
                            <Link to="/contact">Contact</Link>
                        </li>

                        {
                            user && <li className="has-submenu parent-menu-item active">
                                <a href="">Settings</a>
                                <span className="menu-arrow" />
                                <ul className="submenu">
                                    <li className="sub-menu-item"><Link to={'/candidate-detail'}>Profile</Link></li>
                                    <li className="sub-menu-item"><Link to={'/profile-update'}>Settings</Link></li>
                                    <li className="sub-menu-item"><Link onClick={logout}>Logout</Link></li>
                                </ul>
                            </li>
                        }

                        {
                            !localStorage.getItem('auth') && <li><Link to="/login" className="sub-menu-item">Login/Sign up</Link></li>
                        }

                    </ul>
                </div>
            </div>
        </nav>

    )
}
