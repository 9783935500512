import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import image from '../../assets/images/company/1.jpg';
import image1 from '../../assets/images/company/2.jpg';
import image2 from '../../assets/images/company/3.jpg';
import skype from '../../assets/images/company/skype.png';
import Navbar from '../../components/Navbar'
import { Mail, MessageCircle, User } from 'react-feather';
import EmployerDetailComp from '../../components/employer-detail-comp';
import Footer from '../../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../../redux/features/generalSlice';
import CustomLoader from '../../components/CustomLoader';
import moment from "moment";
import JobGridsFourComp from '../../components/job-grids-four-comp';
import ExploreJob from '../../components/Explore-job';

export default function EmployerDetail() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [jobId, setJobId] = useState(null)
    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const clientDetails = useSelector(state => state.generalReducer.clientDetails)

    useEffect(() => {
        dispatch(getClients({ clientId: location.search.split('=')[1] }))
    }, [])

    useEffect(() => {
        if (!clientDetails?.id) return
        if (clientDetails?.jobs && clientDetails?.jobs?.length) {
            document.getElementById('jobsListingContainer').style.padding = '0'
        }
        if(!clientDetails.is_public || !clientDetails.is_visible)
            navigate('/')
    }, [clientDetails])

    function formatString(str) {
        const words = str.toLowerCase().split('_');
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return capitalizedWords.join(' ');
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-40 bg-[url('../../assets/images/hero/bg4.jpg')] bg-center bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/60"></div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>


            <section className="relative mb:pb-24 pb-16 -mt-16 z-1">
                <div className="container">
                    {
                        isApiLoading &&
                        <CustomLoader tipText="Loading Client Details.." />
                    }
                    <div className="grid grid-cols-1" style={{ minHeight: '80px' }}>
                        <div className="md:flex justify-between items-center shadow dark:shadow-gray-700 rounded-md p-6 bg-white dark:bg-slate-900">
                            <div className="flex items-center">
                                {
                                    clientDetails?.id ?
                                        <>
                                            <img src={clientDetails.logo} className="h-20 w-20 p-3 shadow dark:shadow-gray-700 rounded-md bg-slate-50 dark:bg-slate-800" alt="" />

                                            <div className="ms-4">
                                                <h5 className="text-xl font-bold">{clientDetails.name}</h5>
                                                <h6 className="text-base text-slate-400"><i className="uil uil-map-marker"></i> {clientDetails.address}</h6>
                                            </div>
                                        </>
                                        :
                                        <span style={{ fontWeight: 'bold' }}>...</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-12">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-12 md:col-span-12">
                            <h5 className="text-xl font-semibold">Company Story</h5>
                            {
                                clientDetails?.id && <>
                                    <p className="text-slate-400 mt-4" dangerouslySetInnerHTML={{ __html: clientDetails.description }}></p>

                                    {
                                        clientDetails.jobs !== null && clientDetails.jobs?.length ? 
                                        <>
                                            <h5 className="text-xl font-semibold mt-6">Vacancies:</h5>
                                            <JobGridsFourComp jobs={clientDetails.jobs} />
                                        </> : null
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>

                <ExploreJob />
            </section>
            <Footer />

        </>
    )
}
