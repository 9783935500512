import React from 'react'
import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import linkedin from '../assets/images/company/linkedin.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import skype from '../assets/images/company/skype.png';
import spotify from '../assets/images/company/spotify.png';
import telegram from '../assets/images/company/telegram.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import snapchat from '../assets/images/company/snapchat.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import { Link } from "react-router-dom";

export default function JobGridsTwoComp() {
    const JobGridsTwoComp = [
        {
            image: facebook_logo,
            title: 'Facebook',
            day: '2 days ago',
            jobtype: 'Full Time',
            headline: 'Web Designer / Developer',
            country: 'Australia',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: google_logo,
            title: 'Google',
            day: '2 days ago',
            jobtype: 'Part Time',
            headline: 'Marketing Director',
            country: 'USA',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: android,
            title: 'Android',
            day: '2 days ago',
            jobtype: 'Remote',
            headline: 'Application Developer',
            country: 'China',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: lenovo_logo,
            title: 'Lenovo',
            day: '2 days ago',
            jobtype: 'WFT',
            headline: 'Senior Product Designer',
            country: ' Dubai',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: spotify,
            title: 'Spotify',
            day: '2 days ago',
            jobtype: 'Full Time',
            headline: 'C++ Developer',
            country: ' India',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: linkedin,
            title: 'Linkedin',
            day: '2 days ago',
            jobtype: 'Full Time',
            headline: 'Php Developer',
            country: ' Pakistan',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: circle_logo,
            title: 'Circle CI',
            day: '2 days ago',
            jobtype: 'Full Time',
            headline: 'Web Designer / Developer',
            country: 'Australia',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: skype,
            title: 'Skype',
            day: '2 days ago',
            jobtype: 'Full Time',
            headline: 'Marketing Director',
            country: 'USA',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: snapchat,
            title: 'Snapchat',
            day: '2 days ago',
            jobtype: 'Remote',
            headline: 'Application Developer',
            country: 'China',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: shree_logo,
            title: 'Shreethemes',
            day: '2 days ago',
            jobtype: 'WFT',
            headline: 'Senior Product Designer',
            country: ' Dubai',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: telegram,
            title: 'C++ Developer',
            day: '2 days ago',
            jobtype: 'Full Time',
            headline: 'C++ Developer',
            country: ' India',
            applied: '21 applied',
            vacancy: '40 vacancy'
        },
        {
            image: whatsapp,
            title: 'Php Developer',
            day: '2 days ago',
            jobtype: 'Full Time',
            headline: 'Php Developer',
            country: ' Pakistan',
            applied: '21 applied',
            vacancy: '40 vacancy'
        }
    ]
    return (
        <>
            <div className="lg:col-span-8 md:col-span-6">
                <div className="grid lg:grid-cols-2 gap-[30px]">

                    {JobGridsTwoComp.map((item, index) => (

                        <div className="group shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900" key={index}>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                        <img src={item.image} className="h-8 w-8" alt="" />
                                    </div>

                                    <div className="ms-3">
                                        <Link to="/employer-detail" className="block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500">{item.title}</Link>
                                        <span className="block text-sm text-slate-400">{item.day}</span>
                                    </div>
                                </div>

                                <span className="bg-emerald-600/10 group-hover:bg-emerald-600 inline-block text-emerald-600 group-hover:text-white text-xs px-2.5 py-0.5 font-semibold rounded-full transition-all duration-500">{item.jobtype}</span>
                            </div>

                            <div className="mt-6">
                                <Link to="/job-detail-three" className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500">{item.headline}</Link>
                                <h6 className="text-base font-medium"><i className="uil uil-map-marker"></i>{item.country}</h6>
                            </div>

                            <div className="mt-6">
                                <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                    <div className="bg-emerald-600 h-[6px] rounded-full" style={{ width: '55%' }}></div>
                                </div>
                                <div className="mt-2">
                                    <span className="text-slate-400 text-sm"><span className="text-slate-900 dark:text-white font-semibold inline-block">{item.applied}</span>{item.vacancy}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">1</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">2</Link>
                                </li>
                                <li>
                                    <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-emerald-600 border border-emerald-600">3</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">4</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">5</Link>
                                </li>
                                <li>
                                    <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600">
                                        <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}


























































