import React, { useEffect, useState } from 'react'
import spotify from '../assets/images/company/spotify.png';
import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import telegram from '../assets/images/company/telegram.png';
import whatsapp from '../assets/images/company/whatsapp.png';
import { Link } from 'react-router-dom';

export default function EmployerList({ clients }) {
    const [EmployerList, setEmployerList] = useState([]);
    useEffect(() => {
        if (!clients?.length) return
        const clientsTemp = [];
        clients.forEach(client => {
            clientsTemp.push({
                id: client.id,
                brandimage: client.logo,
                title: client.name,
                deta: client.description,
                country: client.address,
                job: `${client.jobs} Jobs`,
                is_public: client.is_public
            })
        });
        setEmployerList(clientsTemp)
    }, [clients])
    return (
        <>
            <div className="container">
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]">
                    {EmployerList.map((item, index) => (
                        <Link to={item.is_public ? `/client-detail?clientId=${item.id}` : ``} className={!item.is_public && 'disabled-link'}>
                            <div className="group relative p-6 rounded-md shadow dark:shadow-gray-700 mt-6">
                                <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-700 rounded-md relative -mt-12" key={index}>
                                    <img src={item.brandimage} className="h-8 w-8" alt="" />
                                </div>

                                <div className="mt-3 customStyling">
                                    <Link to={item.is_public ? `/client-detail?clientId=${item.id}` : ``} className="text-lg hover:text-emerald-600 font-semibold">{item.title}</Link>
                                    <p id='h3Container' style={{ height: '60px', overflowY: 'hidden', lineBreak: 'anywhere' }} dangerouslySetInnerHTML={{ __html: item.deta }} className="text-slate-400 mt-2"></p>
                                </div>

                                <div className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700 flex justify-between">
                                    <span className="text-slate-400"><i className="uil uil-map-marker"></i>{item.country}</span>
                                    <span className="block font-semibold text-emerald-600">{item.job}</span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    )
}
