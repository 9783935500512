import React, { useState } from 'react'
import contact from '../assets/images/svg/contact.svg';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, message } from 'antd';
import { useDispatch } from 'react-redux';
import { sendContactUsMsg } from '../redux/features/generalSlice';

export default function Contact() {

    const [isSendingMsg, setIsSendingMsg] = useState(false)
    const [data, setData] = useState({
        nameOfSchool: "",
        location: "",
        website: "",
        name: "",
        email: "",
        phone: "",
        question: "",
        comments: "",
    })
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function sendMsg() {

        if (
            !data.nameOfSchool ||
            !data.location ||
            !data.name ||
            !data.email ||
            !data.phone ||
            !data.question ||
            !data.comments
        )
            return message.warning("Please provide all the required information")

        setIsSendingMsg(true);
        await dispatch(sendContactUsMsg(data))
        navigate('/')
        message.success("Your message has been sent successfully")
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />

            <section className="relative table w-full py-36 bg-[url('../../assets/images/hero/bg.jpg')] bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Contact Us</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Home</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Contact</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative" style={{ paddingTop: '30px', marginBottom: '50px' }}>
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-12 md:col-span-12">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-[26px] md:leading-normal text-2xl leading-normal font-semibold">Get in touch !</h3>
                                        <p className="text-slate-400">We realise that a school succeeds or fails on its reputation. A significant amount of that reputation rests on the quality of staff that the school employs. This company was formed by two former school directors with many years of experience, who know the difficulties that schools face in recruitment. We have developed a leading technological platform, with the personal touch all the way!</p>
                                        <p style={{ marginTop: '10px' }}>Please fill in the below information and one our team will contact you to arrange an initial consultation
                                        </p>
                                    </div>
                                    <br />
                                    <form>
                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="name" className="font-semibold">Name of school*</label>
                                                <input value={data.nameOfSchool} name="nameOfSchool" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="nameOfSchool" type="text" className="form-input mt-2" placeholder="School" />
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="font-semibold">Location*</label>
                                                <input value={data.location} name="location" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="location" type="text" className="form-input mt-2" placeholder="Location" />
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="font-semibold">Website</label>
                                                <input value={data.website} name="website" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="website" type="text" className="form-input mt-2" placeholder="Website" />
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="font-semibold">Your Name*</label>
                                                <input value={data.name} name="name" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="name" type="text" className="form-input mt-2" placeholder="Name" />
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="font-semibold">Your Email*</label>
                                                <input value={data.email} name="email" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="email" type="email" className="form-input mt-2" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="subject" className="font-semibold">Phone*</label>
                                                <input value={data.phone} name="phone" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="phone" className="form-input mt-2" placeholder="Phone" />
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="subject" className="font-semibold">Average number of hires/year*</label>
                                                <input value={data.question} name="question" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="question" type="number" className="form-input mt-2" placeholder="10" />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="comments" className="font-semibold">Your Comment*</label>
                                                <textarea value={data.comments} name="comments" onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} id="comments" className="form-input mt-2 textarea" placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                        <Button style={{ height: '100%' }} className='btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md' loading={isSendingMsg} onClick={sendMsg}>
                                            Send Message
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <i className="uil uil-phone"></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Phone</h5>
                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="tel:+152534-468-854" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">+152 534-468-854</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <i className="uil uil-envelope"></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Email</h5>
                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>

                                <div className="mt-5">
                                    <Link to="mailto:contact@example.com" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">contact@example.com</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <i className="uil uil-map-marker"></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Location</h5>
                                <p className="text-slate-400 mt-3">C/54 Northwest Freeway, Suite 558, <br /> Houston, USA 485</p>

                                <div className="mt-5">
                                    <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                        data-type="iframe" className="video-play-icon read-more lightbox btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">View on Google map</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <Footer />
        </>
    )
}
