import React from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/logo-light.png';
import { resetPw } from '../../redux/features/generalSlice';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

export default function RecoverPassword() {

    const isApiLoading = useSelector(state => state.generalReducer.isApiLoading)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const resetPwEvent = async () => {
        if(!document.getElementById('pw').value || !document.getElementById('confirmPw').value || document.getElementById('pw').value !== document.getElementById('confirmPw').value)
         return message.warning("Please provide valid password and make sure that both passwords batches")
        const response = await dispatch(resetPw({email: location.search.split('&')[0].split("=")[1], code: location.search.split('&')[1].split("=")[1], password: document.getElementById('pw').value}))
        if(response?.payload?.response?.data?.errorMessage)
            message.error("You've provided invalid recovery code")
        else
            navigate('/login')
    }

    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg1.jpg')] bg-no-repeat bg-center bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
            <div className="container">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                    <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                        <div className="p-6">
                            <Link to="/index">
                                <img src={'/logo_dark.png'} className="mx-auto h-[24px] block dark:hidden" alt="" />
                                <img src={'/logo_light.png'} className="mx-auto h-[24px] dark:block hidden" alt="" />
                            </Link>
                            <h5 className="my-6 text-xl font-semibold">Recover Password</h5>
                            <div className="grid grid-cols-1">
                                <form className="text-left">
                                    <div className="grid grid-cols-1">
                                        <div className="mb-4 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                            <input className="form-input mt-3 rounded-md" placeholder="name@example.com" value={location.search.split('&')[0].split("=")[1]} readOnly/>
                                        </div>
                                        <div className="mb-4 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="LoginEmail">New Password:</label>
                                            <input className="form-input mt-3 rounded-md" type="password" placeholder="**********" id="pw" />
                                        </div>
                                        <div className="mb-4 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="LoginEmail">Confirm Password:</label>
                                            <input className="form-input mt-3 rounded-md" type="password" placeholder="**********" id="confirmPw" />
                                        </div>

                                        <div className="mb-4">
                                            <input type="button" onClick={resetPwEvent} className={`btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full ${isApiLoading && 'disabled'}`} value="Reset Password" />
                                        </div>

                                        <div className="text-center">
                                            <span className="text-slate-400 me-2">Remember your password ? </span><Link to="/login" className="text-black dark:text-white font-bold">Sign in</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
