import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

if(localStorage.getItem('auth') == 'undefined' || localStorage.getItem('auth') == 'null'){
    localStorage.removeItem('auth');
    window.location.reload();
}

const initialState: any = {
    user: (localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null),
    isApiLoading: null,
    profile: null,
    jobs: [],
    jobsPagination: null,
    jobDetails: null,
    clients: null,
    clientDetails: null
};

export const getProfile = createAsyncThunk("get-profile", async ({ jobId }: any, { dispatch }) => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/candidate/profile`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JSON.parse(localStorage.getItem('auth')).jwt}`
        }
    }).catch(e => {
        if (e?.response?.status === 401)
            return dispatch(logoutUser())
        console.log(e)
        return e
    });
});

export const sendRecoveryEmail = createAsyncThunk("recover-password", async ({ email }: any, { dispatch }) => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/forgot-password?email=${email}`, {
        headers: {
            "Content-Type": "application/json"
        }
    }).catch(e => {
        console.log(e)
        return e
    });
});

export const resetPw = createAsyncThunk("reset-password", async ({ email, code, password }: any, { dispatch }) => {
    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/forgot-password/recover?recoveryCode=${code}`, {
        email,
        password
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    }).catch(e => {
        console.log(e)
        return e
    });
});

export const getJobs = createAsyncThunk("get-jobs", async ({ jobId, paginationUrl }: any, { dispatch }) => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/jobs${paginationUrl ? `?paginationUrl=${paginationUrl}` : ``}${jobId ? `?jobId=${jobId}` : ``}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth'))?.jwt : ``}`
        }
    }).catch(e => {
        console.log(e)
        return e
    });
});

export const getClients = createAsyncThunk("get-clients", async ({ clientId }: any, { dispatch }) => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/clients${clientId ? `?clientId=${clientId}` : ``}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth'))?.jwt : ``}`
        }
    }).catch(e => {
        console.log(e)
        return e
    });
});

export const applyJob = createAsyncThunk("job-apply", async (payload: any, { dispatch }) => {
    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/apply`, payload, {
        headers: {
            "Content-Type": "application/json"
        }
    }).catch(e => {
        console.log(e)
        return e
    });
});

export const sendContactUsMsg = createAsyncThunk("contact-us", async (payload: any, { dispatch }) => {
    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/contact-us`, payload, {
        headers: {
            "Content-Type": "application/json"
        }
    }).catch(e => {
        console.log(e)
        return e
    });
});

export const login = createAsyncThunk("login", async (credentials: any, { dispatch }) => {
    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, credentials, {
        headers: {
            "Content-Type": "application/json"
        }
    }).catch(e => {
        console.log(e)
        return e
    });
});

export const generalSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginUser: (state, action) => {
            if(action.payload && action.payload !== undefined && action.payload !== ''){
                localStorage.setItem('auth', JSON.stringify(action.payload));
                state.user = action.payload
            }
        },
        logoutUser: (state, action) => {
            state.profile = null
            localStorage.removeItem('auth')
            state.user = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getJobs.pending, (state, action) => {
            state.isApiLoading = true
        }).addCase(getJobs.fulfilled, (state, action: any) => {
            state.isApiLoading = false
            if (action?.payload?.data?.results){
                state.jobs = action?.payload?.data?.results;
                state.jobsPagination = {...action?.payload?.data, results: []}
            } else
                state.jobDetails = action?.payload?.data;
        }).addCase(resetPw.pending, (state, action) => {
            state.isApiLoading = true
        }).addCase(resetPw.fulfilled, (state, action: any) => {
            state.isApiLoading = false
        }).addCase(sendRecoveryEmail.pending, (state, action) => {
            state.isApiLoading = true
        }).addCase(sendRecoveryEmail.fulfilled, (state, action: any) => {
            state.isApiLoading = false
        }).addCase(getClients.pending, (state, action) => {
            state.isApiLoading = true
        }).addCase(getClients.fulfilled, (state, action: any) => {
            state.isApiLoading = false
            if (!action?.meta?.arg?.clientId)
                state.clients = action?.payload?.data || null;
            else
                state.clientDetails = action?.payload?.data;
        }).addCase(applyJob.fulfilled, (state, action: any) => {
        }).addCase(login.fulfilled, (state, action: any) => {
            action?.payload?.data?.jwt && localStorage.setItem('auth', JSON.stringify(action?.payload?.data))
            state.user = action?.payload?.data
        }).addCase(getProfile.pending, (state, action) => {
            state.isApiLoading = true
        }).addCase(getProfile.fulfilled, (state, action: any) => {
            state.isApiLoading = false
            state.profile = action?.payload?.data
        })
    }
});

export const {
    loginUser,
    logoutUser
} = generalSlice.actions;
export default generalSlice.reducer;
